<app-circle></app-circle>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
<div class="bg-primary-variant" style="height: 100px"></div>
<div class="bg-primary" style="height: 100px"></div>
