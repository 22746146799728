export const clients = [
    {
        id: 1,
        name: 'Banano Tours',
        image: 'images/clients/banano_tours.jpg'
    },
    {
        id: 2,
        name: 'La Locura',
        image: 'images/clients/la_locura.jpeg'
    },
    {
        id: 3,
        name: 'Parador Rojo',
        image: 'images/clients/parador_rojo.jpg'
    },
    {
        id: 4,
        name: 'Lendz',
        image: 'images/clients/lendz.jpg'
    },
    {
        id: 5,
        name: 'Médica Colombia',
        image: 'images/clients/medica_colombia.jpg'
    },
    {
        id: 6,
        name: 'Futbolete',
        image: 'images/clients/futbolete.jpg'
    },
    {
        id: 7,
        name: 'Club de Ejecutivos del Valle del Cauca',
        image: 'images/clients/club_de_ejecutivos.jpg'
    },
    {
        id: 8,
        name: 'Bon Buñuelo',
        image: 'images/clients/bon_bunuelo.jpg'
    },
    {
        id: 9,
        name: 'RUSS Software de Gestión de Costos',
        image: 'images/clients/russ.jpg'
    }
];
