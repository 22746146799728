import { Component, OnInit, Directive } from '@angular/core';
import Skrollr from 'src/assets/javascripts/skrollr/dist/skrollr.min.js';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import { ApplicationStateService } from '../application-state.service';
import {HomeComponentModel} from './home.component.model';

@Directive()
export abstract class HomeComponent implements OnInit{

  private model: HomeComponentModel;
  public myViewModel: HomeComponentModel;

  constructor(private router: Router, private sanitizer: DomSanitizer, private applicationStateService: ApplicationStateService) {
    this.model = new HomeComponentModel(sanitizer);
    this.myViewModel = new HomeComponentModel(sanitizer);

    this.updateView();
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  private updateView(): void {
    this.myViewModel = this.model.clone();
  }

}
