import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-swipe-up',
  templateUrl: './swipe-up.component.html',
  styleUrls: ['./swipe-up.component.sass']
})
export class SwipeUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    const swipeUpContainer = $('.swipe-up-container');
    const timeoutInMilliseconds = 10000;
    let timeoutId;
    let windowPosition;
    let lastWindowPosition;

    $(window).scroll(function() {
      windowPosition = $(window).scrollTop;
      swipeUpContainer.hide();
      resetTimer();
    });

    function resetTimer() {
      window.clearTimeout(timeoutId);
      startTimer();
    }

    function startTimer() {
      timeoutId = window.setTimeout(scrollInspect, timeoutInMilliseconds);
    }

    function scrollInspect() {
      if (windowPosition === lastWindowPosition) {
        swipeUpContainer.show();
      }
      lastWindowPosition = windowPosition;
    }

    $(document).ready(function() {
      startTimer();
    });
  }
}
