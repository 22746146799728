<app-swipe-up></app-swipe-up>
<div class="fab"><a id="paddings-button-1" data-0=" color: rgb(255, 255, 255); display: block;" data-700="color: rgb(255, 255, 255); display: block;" data-1850=" color: rgb(239, 51, 64); display: block;" data-3300=" color: rgb(239, 51, 64); display: block;" data-4250=" color: rgb(239, 51, 64); display: block;" data-4300=" color: rgb(255, 255, 255); display: block;" data-24600=" color: rgb(255, 255, 255); display: block;"  data-24650=" color: rgb(239, 51, 64); display: block;" data-38850="color: rgb(239, 51, 64); display: block;" data-38855=" color: rgb(255, 255, 255); display: block;" href="https://www.facebook.com/Graffignacomunicacionhumana" target="_blank" role="button" aria-pressed="true"><fa-icon [icon]="fafacebook"></fa-icon></a></div>

<div class="fab-2"><a id="paddings-button-2" data-0=" color: rgb(255, 255, 255); display: block;" data-700="color: rgb(255, 255, 255); display: block;" data-1850="color: rgb(239, 51, 64); display: block;" data-3300=" color: rgb(239, 51, 64); display: block;" data-4250=" color: rgb(239, 51, 64); display: block;" data-4300=" color: rgb(255, 255, 255);  display: block;" data-24600=" color: rgb(255, 255, 255); display: block;" data-24650=" color: rgb(239, 51, 64)" data-38850="color: rgb(239, 51, 64); display: block;" data-38855=" color: rgb(255, 255, 255); display: block;" href="https://www.instagram.com/graffigna_agencia/" target="_blank" role="button" aria-pressed="true"><fa-icon [icon]="fainstagram"></fa-icon></a></div>

<div class="fab-3"><a id="paddings-button-3" data-0=" color: rgb(255, 255, 255); display: block;" data-700="color: rgb(255, 255, 255); display: block;" data-1850=" color: rgb(239, 51, 64); display: block;" data-3300=" color: rgb(239, 51, 64); display: block;" data-4250=" color: rgb(239, 51, 64); display: block;" data-4300=" color: rgb(255, 255, 255);  display: block;" data-24600=" color: rgb(255, 255, 255); display: block;" data-24650=" color: rgb(239, 51, 64)" data-38850="color: rgb(239, 51, 64); display: block;" data-38855=" color: rgb(255, 255, 255); display: block;" href="#contact-us" role="button" aria-pressed="true"><fa-icon [icon]="faemail"></fa-icon></a></div>
<div class="circle"
     data-0="transform: scale(1.7) translateX(0rem) translateY(8rem); display: block;"
     data-200="transform: scale(2) translateX(0rem) translateY(8rem); display: block;"
     data-700="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-2000="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-2600="transform: scale(2) translateX(0rem) translateY(8rem); display: block;"
     data-2900="transform: scale(0) translateX(0rem) translateY(8rem); display: none;"
     data-3500="transform: scale(0) translateX(0rem) translateY(8rem); display: block;"
     data-4300="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-5100="transform: scale(0) translateX(0rem) translateY(8rem); display: none;"
     data-23950="transform: scale(0) translateX(0rem) translateY(8rem); display: block;"
     data-24600="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-24900="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-25800="transform: scale(0) translateX(0rem) translateY(8rem); display: none;"
     data-38050="transform: scale(0) translateX(0rem) translateY(8rem); display: none;"
     data-38100="transform: scale(0) translateX(0rem) translateY(8rem); display: block;"
     data-38800="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-39100="transform: scale(6) translateX(0rem) translateY(8rem); display: block;"
     data-40000="transform: scale(0) translateX(0rem) translateY(8rem); display: none;">
</div>
<div class="section-2" id="home">
    <div class="presentation-decorations-1" data-0="right: -96; display:block;" data-1200="right: 600; display:none;"></div>
    <div class="row">
        <div class="offset-5 col-7 logo-g"></div>
        <div class="offset-4 col-7 text-section-1" data-100="left: 0; display:block;" data-1200="left: 980; display:none;"></div>
    </div>
</div>
<div class="section" id="slide-2" data-0="background-color: rgba(83,97,126, 0); position: relative" data-1750="background-color: rgba(83,97,126, 0); position: initial" data-1800="background-color: rgba(218,218,218, 1)">
    <div class="presentation-decorations-2 space-container">

        <h1 class="pl-2">SOMOS <br>CÓMPLICES DE <br>NUESTROS<br>CLIENTES</h1>
    </div>
</div>
<div id="about-us" class="section-3 row m-0" data-4190="background: rgb(218, 218, 218);" data-4200="background: rgb(83, 97, 126);">
    <div class="presentation-decorations-4-1" data-2600="left: -40rem; display: none;" data-2633="left: -40rem; display: block;" data-3133="left: 15rem; display: block;" data-3400="left: 15rem; display: block;" data-3800="left: -40rem; display: none;"></div>
    <div class="offset-7 col-5 z-2 top-64vh">
        <div class="title-slide-4 text-left px-3 pt-5 mb-4" data-3400= "opacity: 1" data-3600 = "opacity: 0">
        </div>
        <div class="text-slide-4 pt-2 px-3">
            <p class="pb-3"  data-3400= "opacity: 1" data-3600="opacity: 0">
                Graffigna no es una agencia de publicidad, tampoco somos una agencia digital, ni somos competencia ni
                intentamos competir con lo que hoy conocemos, nosotros somos una agencia que le habla a los humanos como
                un susurro con nombre de premonición, somos una brisa fría en el medio del verano, en donde antes de un
                informe de tráfico está una sonrisa, una taza de café o en su defecto una copa de vino.
            </p>
            <p class="pb-3"  data-3500= "opacity: 1" data-3700="opacity: 0">
                Llegaremos al éxito no por jugar el mismo juego que hoy se conoce, inventaremos las palabras para
                calificar la agencia, en donde más que un trabajo sembraremos una nueva filosofía capaz de estar al lado
                de las personas, seremos el nuevo nacimiento del Renacimiento.
            </p>
        </div>
    </div>
    <div class="presentation-decorations-4">
    </div>
</div>
<div class="section-4" id="success-stories" data-24600="background: rgb(83, 97, 126);" data-24650="background: rgb(218, 218, 218);">
    <div class="title-slide-5" data-4795="right: -28vw; display: none;" data-4800="right: -28vw; display: block;" data-5500="right: 2vw; display: block;" data-23400="right: 2vw; display: block;" data-24200="right: -28vw; display: none;" >
    </div>

    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[0], 0)">
        <div class="presentation-decorations-5 presentation-decorations-5-1" data-4800="left: -138vw; height: 80vh; width: 76vw;" data-5800="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-6100="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-6900="left: 1vw; top: 18vh; height: 25vh; width: 22vw;" data-22600="display: block; left: 1vw;  top: 18vh; height: 25vh; width: 22vw;" data-23400="display: none; left: -23vw; top: 18vh; height: 25vh; width: 22vw;">
            <div class="w-100 h-100" id="case1"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[1], 1)">
        <div class="presentation-decorations-5 presentation-decorations-5-2" data-6900="left: -138vw; height: 80vh; width: 76vw;" data-7900="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-8300="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-9100="left: 24vw; top: 18vh; height: 25vh; width: 22vw;" data-23000="display: block; left: 24vw;  top: 18vh; height: 25vh; width: 22vw;" data-23800="display: none; left: -23vw; top: 18vh; height: 25vh; width: 22vw;">
            <div class="w-100 h-100" id="case2"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[2], 2)">
        <div class="presentation-decorations-5 presentation-decorations-5-3" data-9100="left: -138vw; height: 80vh; width: 76vw;" data-10100="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-10500="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-11300="left: 47vw; top: 18vh; height: 25vh; width: 22vw;" data-23400="display: block; left: 47vw;  top: 18vh; height: 25vh; width: 22vw;" data-24200="display: none; left: -23vw; top: 18vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case3"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[3], 3)">
        <div class="presentation-decorations-5 presentation-decorations-5-4" data-11300="left: -138vw; height: 80vh; width: 76vw;" data-12300="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-12700="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-13500="left: 7vw; top: 45vh; height: 25vh; width: 22vw;" data-22600="display: block; left: 7vw; top: 45vh; height: 25vh; width: 22vw;" data-23400="display: none; left: -23vw; top: 45vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case4"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[4], 4)">
        <div class="presentation-decorations-5 presentation-decorations-5-5" data-13500="left: -138vw; height: 80vh; width: 76vw;" data-14500="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-14900="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-15700="left: 30vw; top: 45vh; height: 25vh; width: 22vw;" data-23400="display: block; left: 30vw; top: 45vh; height: 25vh; width: 22vw;" data-24200="display: none; left: -23vw; top: 45vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case5"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[5], 5)">
        <div class="presentation-decorations-5 presentation-decorations-5-6" data-15700="left: -138vw; height: 80vh; width: 76vw;" data-16700="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-17100="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-17900="left: 1vw; top: 72vh; height: 25vh; width: 22vw;" data-22600="display: block; left: 1vw; top: 72vh; height: 25vh; width: 22vw;" data-23400="display: none; left: -23vw; top: 72vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case6"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[6], 6)">
        <div class="presentation-decorations-5 presentation-decorations-5-7" data-17900="left: -138vw; height: 80vh; width: 76vw;" data-18900="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-19300="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-20100="left: 24vw; top: 72vh; height: 25vh; width: 22vw;" data-23000="display: block; left: 24vw; top: 72vh; height: 25vh; width: 22vw;" data-23800="display: none; left: -23vw; top: 72vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case7"></div>
        </div>
    </a>
    <a data-toggle="modal" data-target="#successCasesModal" (click)="imgForServiceModal(success_cases[7], 7)">
        <div class="presentation-decorations-5 presentation-decorations-5-8" data-20100="left: -138vw; height: 80vh; width: 76vw;" data-21000="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-21400="left: 10vw; top: 15vh; height: 80vh; width: 76vw;" data-22200="left: 47vw; top: 72vh; height: 25vh; width: 22vw;" data-23400="display: block; left: 47vw; top: 72vh; height: 25vh; width: 22vw;" data-24200="display: none; left: -23vw; top: 72vh; height: 25vh; width: 22vw;">
            <div class="section-5-1 w-100 h-100" id="case8"></div>
        </div>
    </a>
    </div>
<div class="section-6" data-24650="background: rgb(83, 97, 126);" data-24700="background: rgb(218, 218, 218);">
    <div class="container-fluid row">
        <div style="height: 1300px;" class="col-12"></div>
        <div id="services" style="height: 10px;" class="col-12"></div>
        <div class="presentation-decorations-6-1 img-fluid col-5 offset-md-1" data-25700="display: none; right: -50vw;" data-25750="display: block; right: -50vw;" data-26500="display: block; right: 1vw;" data-27000="display: block; right: 1vw;" data-27800="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6 mh-section-6" data-25700="display: none; left: -52vw;" data-25750="display: flex; left: -52vw;" data-26500="display: flex; left: 2vw" data-27000="display: flex; left: 2vw" data-27800="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_1; index as i" class="col-4 d-flex align-items-center" >
                <a data-toggle="modal" data-target="#ServiceModal" (click)="imgForServiceModal(img,0)" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

        <div class="presentation-decorations-6-2 img-fluid col-5 offset-md-1" data-27800="display: none; right: -50vw;" data-27850="display: block; right: -50vw;" data-28600="display: block; right: 1vw;" data-29100="display: block; right: 1vw;" data-29900="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6" data-27800="display: none; left: -52vw;" data-27850="display: flex; left: -52vw;" data-28600="display: flex; left: 2vw" data-29100="display: flex; left: 2vw" data-29900="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_2; index as i" class="col-4 d-flex align-items-center">
                <a target="_blank" href="{{tic_urls[i]}}" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

        <div class="presentation-decorations-6-3 img-fluid col-5 offset-md-1" data-29900="display: none; right: -50vw;" data-29950="display: block; right: -50vw;" data-30700="display: block; right: 1vw;" data-31200="display: block; right: 1vw;" data-32000="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6" data-29900="display: none; left: -52vw;" data-29950="display: flex; left: -52vw;" data-30700="display: flex; left: 2vw" data-31200="display: flex; left: 2vw" data-32000="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_3; index as i" class="col-4 d-flex align-items-center">
                <a data-toggle="modal" data-target="#ServiceModal" (click)="imgForServiceModal(img,0)" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

        <div class="presentation-decorations-6-4 img-fluid col-5 offset-md-1" data-32000="display: none; right: -50vw;" data-32050="display: block; right: -50vw;" data-32800="display: block; right: 1vw;" data-33300="display: block; right: 1vw;" data-34100="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6" data-32000="display: none; left: -52vw;" data-32050="display: flex; left: -52vw;" data-32800="display: flex; left: 2vw" data-33300="display: flex; left: 2vw" data-34100="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_4; index as i" class="col-4 d-flex align-items-center">
                <a data-toggle="modal" data-target="#ServiceModal" (click)="imgForServiceModal(img,0)" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

        <div class="presentation-decorations-6-5 img-fluid col-5 offset-md-1" data-34100="display: none; right: -50vw;" data-34150="display: block; right: -50vw;" data-34900="display: block; right: 1vw;" data-35400="display: block; right: 1vw;" data-36200="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6" data-34100="display: none; left: -52vw;" data-34150="display: flex; left: -52vw;" data-34900="display: flex; left: 2vw" data-35400="display: flex; left: 2vw" data-36300="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_5; index as i" class="col-5 d-flex align-items-center">
                <a data-toggle="modal" data-target="#ServiceModal" (click)="imgForServiceModal(img,0)" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

        <div class="presentation-decorations-6-6 img-fluid col-5 offset-md-1" data-36100="display: none; right: -50vw;" data-36150="display: block; right: -50vw;" data-36900="display: block; right: 1vw;" data-37400="display: block; right: 1vw;" data-38200="display: none; right: -50vw;"></div>

        <div class="row m-0 table-section-6 col-6" data-36100="display: none; left: -52vw;" data-36150="display: flex; left: -52vw;" data-36900="display: flex; left: 2vw" data-37400="display: flex; left: 2vw" data-38200="display: none; left: -52vw;">
            <div *ngFor="let img of table_service_6; index as i" class="col-5 d-flex align-items-center">
                <a data-toggle="modal" data-target="#ServiceModal" (click)="imgForServiceModal(img,0)" class="position-relative">
                    <img src={{img}} class="img-fluid py-2">
                    <div class="section-5-1 w-100 h-100"></div>
                </a>
            </div>
        </div>

    </div>
</div>
<div class="section-7 container-fluid" data-38850="background: rgb(218, 218, 218);" data-38855="background: rgb(83, 97, 126);">
    <div class="row">
        <div style="height: 9500px;" class="col-12"></div>
        <div id="team" style="height: 10px;" class="col-12"></div>
    </div>
    <div class="title-slide-7" data-39800="left: -28vw; display: none;" data-39850="left: -28vw; display: block;" data-40600="left: 2vw; display: block;" data-48600="left: 2vw; display: block;" data-49400="left: -28vw; display: none;" ></div>
    <div class="team team-1" data-39800="top: 15vh; right: -13vw;" data-40600="top: 15vh; right: 82vw;" data-48600="top: 15vh; right: 82vw;" data-49400="top: -35vh; right: 82vw;"></div>
    <div class="team team-2" data-40600="top: 15vh; right: -13vw;" data-41400="top: 15vh; right: 69vw;" data-48600="top: 15vh; right: 69vw;" data-49400="top: -35vh; right: 69vw;"></div>
    <div class="team team-3" data-41400="top: 15vh; right: -13vw;" data-42200="top: 15vh; right: 56vw;" data-48600="top: 15vh; right: 56vw;" data-49400="top: -35vh; right: 56vw;"></div>
    <div class="team team-4" data-42200="top: 15vh; right: -13vw;" data-43000="top: 15vh; right: 43vw;" data-48600="top: 15vh; right: 43vw;" data-49400="top: -35vh; right: 43vw;"></div>
    <div class="team team-5" data-43000="top: 15vh; right: -13vw;" data-43800="top: 15vh; right: 30vw;" data-48600="top: 15vh; right: 30vw;" data-49400="top: -35vh; right: 30vw;"></div>
    <div class="team team-6" data-44600="top: 15vh; right: -13vw;" data-45400="top: 15vh; right: 17vw;" data-48600="top: 15vh; right: 17vw;" data-49400="top: -35vh; right: 17vw;"></div>
    <div class="team team-7" data-45400="top: 15vh; right: -13vw;" data-46200="top: 15vh; right: 4vw;" data-48600="top: 15vh; right: 4vw;" data-49400="top: -35vh; right: 4vw;"></div>
    <div class="team team-8" data-44600="top: 54vh; right: -13vw;" data-45400="top: 54vh; right: 62vw;" data-49000="top: 54vh; right: 62vw;" data-49800="top: -35vh; right: 62vw;"></div>
    <div class="team team-9" data-45400="top: 54vh; right: -13vw;" data-46200="top: 54vh; right: 49vw;" data-49000="top: 54vh; right: 49vw;" data-49800="top: -35vh; right: 49vw;"></div>
    <div class="team team-10" data-46200="top: 54vh; right: -13vw;" data-47000="top: 54vh; right: 36vw;" data-49000="top: 54vh; right: 36vw;" data-49800="top: -35vh; right: 36vw;"></div>
    <div class="team team-11" data-47000="top: 54vh; right: -13vw;" data-47800="top: 54vh; right: 23vw;" data-49000="top: 54vh; right: 23vw;" data-49800="top: -35vh; right: 23vw;"></div>
    <div class="team team-12" data-47800="top: 54vh; right: -13vw;" data-48600="top: 54vh; right: 10vw;" data-49000="top: 54vh; right: 10vw;" data-49800="top: -35vh; right: 10vw;"></div>
</div>
<div class="section-8">
    <div class="row mx-5">
        <div class="col-12 mb-5">
            <img src="/assets/images/titles/graffigna-our-customers.png">
        </div>
        <div class="col-12">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig_2">
                <div ngxSlickItem *ngFor="let img of carousel_img; index as i" class="slide ml-2">
                    <a data-toggle="modal" id="button-img">
                        <img src={{img}} width="100px">
                    </a>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>

<div class="modal fade" id="ServiceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content full-modal">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body p-0">
                <div class="text-center m-0 img-servicemodal">
                    <img src={{imgServiceModal}}>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="successCasesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content full-modal-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body p-0">
                <div class="card">
                    <div class="text-center m-0 img-successCasesModal vh-50">
                        <img src={{imgServiceModal}}>
                    </div>
                    <div class="card-body h-50 overflow-auto">
                        <h1 class="font-weight-bold">{{title_success_cases}}</h1>
                        <p class="card-text">{{paragraph_success_cases_1}}</p>
                        <p class="card-text">{{paragraph_success_cases_2}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
