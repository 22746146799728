import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { services } from './services';
import { clients} from '../client-portfolio/clients';

export class HomeComponentModel {

    services = services;
    clients = clients;

    constructor(private sanitizer: DomSanitizer) {
    }

    flipCard(event, client) {
        console.log('start flipCard');
        // @ts-ignore
        $('#client-cards-' + client.id).toggleClass('flipped');
    }

    public clone(): HomeComponentModel {
        let clonedModel: HomeComponentModel = new HomeComponentModel(this.sanitizer);

        return clonedModel;
    }
}
