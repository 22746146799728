import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faBullhorn, faEnvelope, fas, faLocationArrow, faTimes, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { ApplicationStateService } from './application-state.service';
import { AppComponentModel } from './app.component.model';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [NgbCarouselConfig],
  animations: []
})

export class AppComponent implements OnInit {
  title = 'graffigna-agency';
  public myViewModel: AppComponentModel;
  public isMobileResolution: boolean;
  private model: AppComponentModel;
  faCopyright = faCopyright;
  fafacebook = faFacebook;
  fainstagram = faInstagram;
  faemail = faEnvelope;
  falocationarrow = faLocationArrow;
  fabullhorn = faBullhorn;
  faTimes = faTimes;

  constructor(library: FaIconLibrary, carousel: NgbCarouselConfig, private applicationStateService: ApplicationStateService) {
    library.addIconPacks(fas, far);
    carousel.interval = 10000;
    carousel.wrap = false;
    carousel.keyboard = false;
    carousel.pauseOnHover = false;
    this.model = new AppComponentModel();
    this.myViewModel = new AppComponentModel();

    this.isMobileResolution = this.applicationStateService.getIsMobileResolution();
  }

  ngOnInit(): void  {
    $('.toggle-button').click(function(e) {
      e.preventDefault();
      $('#wrapper').toggleClass('toggled');
    });
  }

  private updateView(): void {
    this.myViewModel = this.model.clone();
  }
}
