<div class="row">
    <ng-container *ngFor="let client of clients">
        <ng-template #content let-modal>
            <div class="modal-header">
                <h4 class="modal-title">{{ client.name }}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <img src="assets/{{ client.image }}" class="img-fluid" alt="Responsive Image">
            </div>
        </ng-template>
        <div class="col-md-4 px-0">
            <a (click)="openClientModal(content)">
                <img src="assets/{{ client.image }}" alt="Responsive Image"
                     style="filter: grayscale(100%); height: 390px; width: 390px;">
            </a>
        </div>
    </ng-container>
</div>

