<div class="section section-1" id="home">
    <div class="presentation-decorations-1 space-container">
    </div>
    <div class="circle">
    </div>
</div>
<!--<div class="section section-2">-->
<!--    <div class="presentation-decorations-2-1 space-container">-->
<!--    </div>-->
<!--    <div class="presentation-decorations-2-2 space-container">-->
<!--    </div>-->
<!--</div>-->
<div class="section section-3">
    <div class="presentation-decorations-2-1 space-container">
    </div>
    <h1 class="title">Somos<br>cómplices<br>de nuestros<br>clientes</h1>
</div>
<div class="section section-4" id="about-us">
    <div class="text-center position-relative z-index-2">
        <img src="assets/images/titles/VANGOGH.png" class="title" alt="About Us Title">
    </div>
    <div class="text p-3 position-relative z-index-2">
        <p>
            Graffigna no es una agencia de publicidad, tampoco somos una agencia digital, ni somos competencia ni
            intentamos competir con lo que hoy conocemos, nosotros somos una agencia que le habla a los humanos como
            un susurro con nombre de premonición, somos una brisa fría en el medio del verano, en donde antes de un
            informe de tráfico está una sonrisa, una taza de café o en su defecto una copa de vino.
        </p>
        <p class="pb-3">
            Llegaremos al éxito no por jugar el mismo juego que hoy se conoce, inventaremos las palabras para
            calificar la agencia, en donde más que un trabajo sembraremos una nueva filosofía capaz de estar al lado
            de las personas, seremos el nuevo nacimiento del Renacimiento.
        </p>
    </div>
    <div class="presentation-decorations-2-1 space-container"></div>
</div>
<div class="section section-5" id="success-stories">
    <div class="position-relative z-index-2">
        <swiper [config]="swiperConfig">
            <ng-template swiperSlide *ngFor="let story of successStories; index as i">
                <a data-toggle="modal">
                    <img src={{story}} alt="Graffigna Service">
                </a>
            </ng-template>
        </swiper>
    </div>
    <img src="assets/images/titles/graffigna-CASOS-DE-EXITO_WEBPAGE.png" class="title" alt="Success Stories Title">
    <div class="presentation-decorations-2-1 space-container"></div>
</div>
<div class="section section-6" id="services">
    <div class="position-relative z-index-2">
        <swiper [config]="swiperServicesConfig">
            <ng-template swiperSlide *ngFor="let service of services; index as i">
                <a data-toggle="modal">
                    <img src={{service}} alt="Graffigna Service">
                </a>
            </ng-template>
        </swiper>
    </div>
    <div class="presentation-decorations-2-1 space-container"></div>
</div>
<div class="section section-7">
    <div class="presentation-decorations-2-1 space-container"></div>
    <h1 class="title">Nuestro<br>Team</h1>
</div>
<div class="section section-8" id="team">
    <div class="position-relative z-index-2">
        <swiper [config]="swiperConfig">
            <ng-template swiperSlide *ngFor="let member of ourTeam; index as i">
                <a data-toggle="modal">
                    <img src={{member}} alt="Graffigna Service">
                </a>
            </ng-template>
        </swiper>
    </div>
    <div class="presentation-decorations-2-1 space-container"></div>
</div>
<div class="section section-9">
    <div class="position-relative z-index-2">
        <swiper [config]="swiperConfig">
            <ng-template swiperSlide *ngFor="let client of ourClients; index as i">
                <a data-toggle="modal">
                    <img src={{client}} alt="Graffigna Client">
                </a>
            </ng-template>
        </swiper>
    </div>
    <div class="text-center position-relative z-index-2">
        <img src="assets/images/titles/graffigna-our-customers.png" class="title" alt="Our Customers Title">
    </div>
    <div class="presentation-decorations-2-1 space-container"></div>
</div>
<div class="section section-10" id="contact-us">
    <div class="position-relative text-center">
        <h3 class="title">Contáctanos</h3>
        <app-branch-map></app-branch-map>

            <a href="mailto:info@graffigna.agency" class="btn btn-block btn-footer">info@graffigna.agency</a>

        <div class="text-center position-relative z-index-2">
            <img src="assets/images/LOGO-VERTICAL-BLANCO.png" class="logo" alt="Graffigna Agency Logo">
        </div>
    </div>
    <div class="presentation-decorations-2-1 space-container">
    </div>
</div>
