<div id="wrapper">
  <div class="bg-primary shadow fixed-top d-flex align-items-start flex-column p-3" id="sidebar-wrapper">
    <div class="mb-auto mt-2 w-100 text-right">
      <img class="mr-auto toggle-button" src="assets/images/graffigna-times-white.svg">
    </div>
    <div class="list-group w-100 text-center">
      <a href="#home" class="list-group-item list-group-item-action">Inicio</a>
      <a href="#about-us" class="list-group-item list-group-item-action">Quienes Somos</a>
      <a href="#success-stories" class="list-group-item list-group-item-action">Casos de Éxito</a>
      <a href="#services" class="list-group-item list-group-item-action">Servicios</a>
      <a href="#team" class="list-group-item list-group-item-action">Team</a>
      <a href="#contact-us" class="list-group-item list-group-item-action">Contacto</a>
    </div>
    <div class="mt-auto w-100 text-center sidebar-footer">
      <img src="assets/images/logo-isotype-white.svg" class="img-fluid" alt="Responsive image">
      <p class="mb-0 mt-1"><fa-icon [icon]="faCopyright"></fa-icon> 2021 Graffigna Comunicación Humana</p>
      <p>Todos los derechos reservados</p>
    </div>
  </div>
  <div id="page-content-wrapper">
    <nav class="navbar fixed-top" data-1700="background: rgb(83, 97, 126);" data-1800="background: rgb(218, 218, 218);"
           data-4190="background: rgb(218, 218, 218);" data-4200="background: rgb(83, 97, 126);"
           data-6574="background: rgb(83, 97, 126);" data-24650="background: rgb(83, 97, 126);"
           data-24700="background: rgb(218, 218, 218);" data-38850="background: rgb(218, 218, 218);"
           data-38855="background: rgb(83, 97, 126);" id="navbar">
      <a class="navbar-brand navbar-brand-mobile" href="/">
        <img src="assets/images/logo.png" class="img-fluid" alt="Responsive image" id="logo">
      </a>
      <button id="navbar-toggler" class="navbar-toggler menu toggle-button" type="button"></button>
    </nav>
  </div>
  <router-outlet></router-outlet>
</div>
<footer class="m-0 bg-red" id="contact-us">
    <div class="row m-0 mb-4">
      <div class="col-12 title-footer text-left px-3 text-center">
        <h1>Contáctenos</h1>
      </div>
      <div class="col-12 px-3 text-center">
        <p>
          Permítanos evaluar sus necesidades y desafíos juntos
        </p>
      </div>
    </div>
    <div class="row m-0 w-100">
      <div class="col-12 mb-3">
        <div class="row justify-content-center w-100">
          <div class="col-1 text-right mt-2">
            <fa-icon [icon]="falocationarrow"></fa-icon>
          </div>
          <div class="col-5 pl-0">
            <a href="mailto:info@graffigna.agency" class="btn btn-block btn-footer">info@graffigna.agency</a>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <app-branch-map></app-branch-map>
      </div>
    </div>
    <div class="row m-0 bg-white pb-1">
      <div class="col-12 text-center">
        <img src="assets/images/15 Logo-PNG-alta (1).png" style="width: 180px;">
      </div>
    </div>
  </footer>
