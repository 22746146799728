import {Component, OnInit} from '@angular/core';
import {MessageService} from "../services/message.service";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {

    name: string;
    email: string;
    subject: string;
    message: string;
    faEnvelope = faEnvelope;
    constructor(public _MessageService: MessageService) {
    }

    ngOnInit(): void {
    }

    submitForm(form) {
        this._MessageService.sendMessage(form).subscribe(() => {
            alert("Mensaje enviado correctamente");
        });
    }

}
