import {Injectable} from '@angular/core';

@Injectable()
export class ApplicationStateService {

    private isMobileResolution: boolean;

    constructor() {
        this.isMobileResolution = window.innerWidth <= 1024 || (window.innerHeight <= 600);
    }

    public getIsMobileResolution(): boolean {
        return this.isMobileResolution;
    }
}
