<div class="test-container"
     data-0="z-index: 99"
     data-250="z-index: 101"
     data-350="z-index: 101"
     data-850="z-index: 99"
     data-900="z-index: 101"
     data-1150="z-index: 101"
     data-1500="z-index: 99"
     data-1800="z-index: 101"
     data-1950="z-index: 101">
    <div id="graffi" class="circle-graffigna"
         data-0="transform: scale(1) translateX(35vw) translateY(-10rem)"
         data-250="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-350="transform: scale(5) translateX(0vw) translateY(0rem)"
         data-450="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-700="transform: scale(1) translateX(-35vw) translateY(-10rem)"
         data-900="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-1000="transform: scale(5) translateX(0vw) translateY(0rem)"
         data-1100="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-1500="transform: scale(1) translateX(35vw) translateY(10rem)"
         data-1800="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-1900="transform: scale(5) translateX(0vw) translateY(0rem)"
         data-2000="transform: scale(1) translateX(0vw) translateY(0rem)"
         data-2300="transform: scale(1) translateX(35vw) translateY(-10rem)">
    </div>
</div>

