export const services = [
    {
        name: 'BRANDING',
        image: 'assets/images/artists/frida.png'
    },
    {
        name: 'TIC',
        image: 'assets/images/artists/picasso.png'
    },
    {
        name: 'ENDO<br>MARKETING',
        image: 'assets/images/artists/pollock.png'
    },
    {
        name: 'SOCIAL<br>MEDIA',
        image: 'assets/images/artists/marta.png'
    },
    {
        name: 'EMAIL<br>MARKETING',
        image: 'assets/images/artists/van_gogh.png'
    },
    {
        name: 'CRM<br>MARKETING',
        image: 'assets/images/artists/monet.png'
    }
];
