<div class="col-1 text-right mt-3">
    <fa-icon [icon]="faEnvelope"></fa-icon>
</div>
<div class="col-7 pl-0">
    <form (ngSubmit)="submitForm(form.value)" #form="ngForm" class="border-email-footer">
        <div class="input-group">
            <input type="email" class="form-control border-input-footer" placeholder="Escribe tu correo electrónico"
                   name="email" [(ngModel)]="email">
            <div class="input-group-append">
                <span class="input-group-text boton-email" type="submit">Enviar</span>
            </div>
        </div>
    </form>
</div>