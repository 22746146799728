import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-branch-map',
  templateUrl: './branch-map.component.html',
  styleUrls: ['./branch-map.component.sass']
})
export class BranchMapComponent implements OnInit {

  branches = [
    {
      id: 1,
      name: 'Cali, Colombia',
      latitude: 3.3952332,
      longitude: -76.5957049
    },
    {
      id: 2,
      name: 'Bógota, Colombia',
      latitude: 4.6486259,
      longitude: -74.247895
    },
    {
      id: 3,
      name: 'Miami, EE. UU.',
      latitude: 26.0274747,
      longitude: -80.6003957
    }
  ];
  iconUrl = {
    url: 'assets/images/logo-isotype-full.svg',
    scaledSize: {
      height: 35,
      width: 35
    }
  };
  zoom = 3.5;

  constructor() { }

  ngOnInit(): void {
  }

}
