
export class AppComponentModel {

    constructor() {
    }

    public clone(): AppComponentModel {
        let model: AppComponentModel = new AppComponentModel();
        return model;
    }
}