import { Component, OnInit } from '@angular/core';
import Skrollr from '../../assets/javascripts/skrollr/dist/skrollr.min';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.sass']
})
export class CircleComponent implements OnInit {

  constructor() { }

  skroll = Skrollr.init();


  ngOnInit(): void {
    this.skroll.refresh();
  }
}
