import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CoreModule } from './core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EngineComponent } from './engine/engine.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ClientPortfolioComponent } from './client-portfolio/client-portfolio.component';
import { CircleComponent } from './circle/circle.component';
import { ClientsComponent } from './clients/clients.component';
import { HomeComponentMobile } from './home/home.component.mobile';
import { HomeComponentDesktop } from './home/home.component.desktop';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SwipeUpComponent } from './swipe-up/swipe-up.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from './services/message.service';
import { BranchMapComponent } from './branch-map/branch-map.component';
import { AgmCoreModule } from '@agm/core';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [
        AppComponent,
        EngineComponent,
        ClientPortfolioComponent,
        CircleComponent,
        ClientsComponent,
        HomeComponentMobile,
        HomeComponentDesktop,
        SwipeUpComponent,
        ContactComponent,
        BranchMapComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        FontAwesomeModule,
        SlickCarouselModule,
        NgbModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDjsIMw_rWcAwG3bRdMXTkfNSNelAxHzmg'
        }),
        SwiperModule
    ],
    providers: [MessageService],
    bootstrap: [AppComponent]
})
export class AppModule { }
