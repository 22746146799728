<!--<agm-map *ngFor="let branch of branches">-->
<!--    <agm-marker [latitude]="branch.latitude" [longitude]="branch.longitude"></agm-marker>-->
<!--</agm-map>-->

<agm-map [latitude]="15" [longitude]="-76" [zoom]="zoom">
    <agm-marker
            *ngFor="let branch of branches"
            [latitude]="branch.latitude"
            [longitude]="branch.longitude"
            [iconUrl]="iconUrl">
        <agm-info-window>
            <strong class="agm-info-window-content">{{branch.name}}</strong>
        </agm-info-window>
    </agm-marker>
</agm-map>
