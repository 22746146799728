import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { clients } from './clients';

@Component({
  selector: 'app-client-portfolio',
  templateUrl: './client-portfolio.component.html',
  styleUrls: ['./client-portfolio.component.sass']
})
export class ClientPortfolioComponent {
  clients = clients;

  constructor(private modalService: NgbModal) {}

  openClientModal(content) {
    this.modalService.open(content, { size: 'sm'});
  }
}
