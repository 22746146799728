import {AfterViewInit, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {ApplicationStateService} from '../application-state.service';
import {HomeComponent} from './home.component';
import * as $ from 'jquery';
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faArrowLeft, faArrowRight, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import SwiperCore, {EffectCoverflow, Pagination, SwiperOptions} from 'swiper';


SwiperCore.use([EffectCoverflow, Pagination]);

@Component({
    selector: 'app-home-mobile',
    templateUrl: './home.component.mobile.html',
    styleUrls: ['./home.component.mobile.sass'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponentMobile extends HomeComponent implements AfterViewInit, OnInit {
    constructor(router: Router, sanitizer: DomSanitizer, applicationStateService: ApplicationStateService ) {
        super(router, sanitizer, applicationStateService);
        this.boxDone = false;
        console.log('Mobile version');
        $('footer').remove();
        this.windows_size();
        $('app-home-mobile').css('overflow', 'hidden');
        $('body').css('overflow', 'hidden');
        for (let i = 1; i < 37; i++) {
            this.ourClients.push('assets/images/client logos/graffigna-' + i + '.png');
        }
    }
    fafacebook = faFacebook;
    fainstagram = faInstagram;
    faemail = faEnvelope;
    farrowleft = faArrowLeft;
    faarrowright = faArrowRight;
    boxDone: boolean;
    closeResult = '';
    images;
    imgServiceModal;
    successStories = [
        'assets/images/success cases/mobile/graffigna-ss-mobile-1.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-2.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-3.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-4.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-5.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-6.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-7.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-8.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-9.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-10.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-11.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-12.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-13.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-14.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-15.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-16.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-17.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-18.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-19.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-20.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-21.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-22.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-23.jpg',
        'assets/images/success cases/mobile/graffigna-ss-mobile-24.jpg'
    ];
    services = [
        'assets/images/services/EndoMarketing.png',
        'assets/images/services/CRM-Marketing.png',
        'assets/images/services/SocialMedia.png',
        'assets/images/services/Tic.png',
        'assets/images/services/Apps.png',
        'assets/images/services/Branding.png'
    ];
    ourClients = [];
    ourTeam = [
        '/assets/images/team/graffigna-2-Alejo-Bernal.png',
        '/assets/images/team/graffigna-3-Maco.png',
        '/assets/images/team/graffigna-4-Victor-M-Benitez.png',
        '/assets/images/team/graffigna-5-Juan-Fernando-Henao.png',
        '/assets/images/team/graffigna-6-Micaela-Pico.png',
        '/assets/images/team/graffigna-7-Rocio-Estella.png',
        '/assets/images/team/graffigna-8-Daniel-Ramirez.png',
        '/assets/images/team/graffigna-9-Jonatan-De-La-Ossa.png',
        '/assets/images/team/graffigna-10-Magnolia-Sinisterra.png',
        '/assets/images/team/graffigna-11-Andres-Giraldo.png',
        '/assets/images/team/graffigna-12-Nano-Sanchez.png',
        '/assets/images/team/graffigna-1-Daniel-Graffigna.png'
    ];
    text_service_section;
    width;
    height;

    swiperConfig: SwiperOptions = {
        slidesPerView: 1.5,
        effect: 'coverflow',
        centeredSlides: true,
        grabCursor: true,
        pagination: false,
        loop: true,
        spaceBetween: 10,
        autoHeight: true,
        coverflowEffect: {
            scale: 0.9,
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: false
        }
    };

    swiperServicesConfig: SwiperOptions = {
        slidesPerView: 1.25,
        effect: 'coverflow',
        centeredSlides: true,
        grabCursor: true,
        pagination: false,
        loop: true,
        spaceBetween: 10,
        autoHeight: true,
        coverflowEffect: {
            scale: 0.9,
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: true
        }
    };

    swiperTeamConfig: SwiperOptions = {
        slidesPerView: 1.5,
        effect: 'coverflow',
        centeredSlides: true,
        grabCursor: true,
        pagination: false,
        loop: true,
        spaceBetween: 10,
        autoHeight: true,
        coverflowEffect: {
            scale: 0.9,
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 1,
            slideShadows: false
        }
    };

    // Carousel
    title = 'ngSlick';

    slideConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: '20px',
    };

    slideConfig_2 = {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        infinite: true,
        arrows: false,
        centerMode: true,
        centerPadding: '0px'
    };

    public totop() {
        window.scroll(0, 0);
    }

    ngAfterViewInit() {
        $(function() {
            console.log('ngAfterViewInit');
            $('html').removeAttr('style');
            $('html').css('overflow-x', 'hidden');
            // $('.draggable').css('height', '40vh');
            // $('.draggable').removeClass('draggable');
            // $('.carousel .slick-track').css('height', '40vh');
            // $('.carousel-slide-6 .slick-track').css('height', '50vh');
            // $('.carousel-slide-6 .slick-list').css('height', '50vh');
            // $(".carousel-slide-6 .slick-track").css("left", "-38vw");
            $('app-branch-map agm-map .sebm-google-map-container-inner').addClass('mobile-google-maps');
        });
        this.windows_size();
        // this.switch_elements();
    }

    @HostListener('window:resize', ['$event'])
    public sizeChange(event) {
        this.windows_size();
    }

    public windows_size() {
        this.width = $( window ).width() + 'px/1025px';
        this.height = window.innerHeight - 1 + 'px/600px';
        $('#width-info').text(this.width);
        $('#height-info').text(this.height);
        if ($( window ).width() < 1025) {
            $('#img-width').attr('src', '/assets/images/sm-width.svg');
        } else {
            $('#img-width').attr('src', '/assets/images/fl-width.svg');
        }
        if ( window.innerHeight < 600) {
            $('#img-height').attr('src', '/assets/images/sm-height.svg');
        } else {
            $('#img-height').attr('src', '/assets/images/fl-height.svg');
        }

        if ($( window ).width() > 1024 && window.innerHeight > 600) {
            location.reload();

        }
    }

    // public add_text_service_section(){
    // this.table_service_section = ["assets/images/services/table/1.jpg", "assets/images/services/table/2.jpg", "assets/images/services/table/3.jpg","assets/images/services/table/4.jpg", "assets/images/services/table/5.jpg", "assets/images/services/table/6.jpeg", "assets/images/services/table/7.jpg", "assets/images/services/table/8.jpg", "assets/images/services/table/9.jpg"];
    // this.text_service_section = ["1 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.", "2 -Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.", "<strong>Social Media</strong> Buscamos impactar y crear un mensaje de valor para que nuestros clientes puedan aprovechar el engagement del content markenting y se solidifique el awareness de su marca.", "4 - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi."];
    // }

    public changeparagraph(id: number) {
        for (let i = 0; i < 4; i++) {
            if (i == id) {
                $('#services-img-' + i + ' a img').removeClass('opacity-50');
                $('#services-p-' + i).removeClass('d-none');
                $('#services-p-' + i + ' p strong').css('color', '#EF3340');
            } else if (i != id) {
                $('#services-img-' + i + ' a img').addClass('opacity-50');
                $('#services-p-' + i).addClass('d-none');
            }
        }
    }

    public imgForModal(type: number) {
        if (type % 2 == 0) {
            this.images = ['assets/images/clients/banano_tours.jpg', 'assets/images/clients/parador_rojo.jpg', 'assets/images/clients/la_locura.jpeg', 'assets/images/clients/lendz.jpg'];
        } else {
            this.images = ['assets/images/modals/folleto.jpg', 'assets/images/modals/sobre_carta_MODELO.jpg', 'assets/images/modals/tarjetas.jpg', 'assets/images/modals/46304544_663188637408673_4769828604051718144_o.png'];
        }
    }
    public imgForServiceModal(id: number) {
        this.imgServiceModal = 'assets/images/services/table/' + id + '.jpg';
    }
    public restoreToModal() {
        $('#restoreModal').addClass('d-none');
        $.each([0, 1, 2, 3], function( index, value ) {
            $('#' + index).removeClass('d-none');
            $('#' + index).removeClass('col-12');
            $('#' + index).addClass('col-3 img-modal');
        });
    }

    public modalOurClients(id: number) {
        $('#restoreModal').removeClass('d-none');
        $.each([0, 1, 2, 3], function( index, value ) {
            if (index == id) {
                $('#' + id).removeClass('d-none');
                $('#' + id).addClass('col-12 img-modal');
            } else {
                $('#' + index).removeClass();
                $('#' + index).addClass('col-3 img-modal d-none');
            }
        });
    }

    public switch_elements() {
        while (true) {
           /* Ventana de Visualización*/
            let posTopView = $(window).scrollTop();
            let posButView = posTopView + $(window).height();
            /* Elemento a validar*/
            let elemTop = $('#slide-2').offset().top;
            let elemBottom = elemTop + $('#slide-2').height();
            /* Comparamos los dos valores tanto del elemento como de la ventana*/
            console.log((elemBottom < posButView && elemBottom > posTopView) || (elemTop > posTopView && elemTop < posButView));
        }
    }

    slickInit(e) {
        console.log('slick initialized');
    }
    // end carousel
}
